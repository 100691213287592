import React from "react";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

class Impressum extends React.Component {
  render() {
    return (
      <div className="termsAndConditions">
        <Helmet>
          <title>Impressum</title>
        </Helmet>
        <Container>
          <Link className="terms" to="/">
            Početna
          </Link>
          <h1 className="pageTitle">Impressum</h1>
          <p>Ime i prezime vlasnika: LUKA GRGIĆ, SLAVONSKI BROD, DUNAVSKA 14</p>
          <p>OIB vlasnika: LUKA GRGIĆ (36670123504)</p>
          <p>
            Naziv obrta: TERRAFORM, obrt za izradu biljnih terarija i obradu
            ravnog stakla, vl. Luka Grgić, Slavonski Brod, Dunavska 14
          </p>
          <p>Matični broj obrta: 98340034</p>
          <p>Kontakt: info@terraform.hr</p>
        </Container>
      </div>
    );
  }
}

export default Impressum;
